










































































































































































import CenteredColumnLayout from '@/components/CenteredColumnLayout.vue'
import PageTitle from '@/components/PageTitle.vue'
import { InputSetups } from "../../mixins/input-setups";
import Placeholders from "@/mixins/placeholders/placeholders";

import { MediaEditorMode } from 'piramis-base-components/src/components/MessageEditorWithMedia/types'
import { UseFields } from "piramis-base-components/src/components/Pi/index";

import { Component, Mixins } from 'vue-property-decorator'

@Component({
  components: {
    CenteredColumnLayout,
    PageTitle
  },
  data() {
    return {
      MediaEditorMode,
    }
  }
})
export default class ModerationCommands extends Mixins(UseFields, InputSetups, Placeholders) {

}
