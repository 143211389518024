var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('centered-column-layout',[_c('page-title',{scopedSlots:_vm._u([{key:"custom-button",fn:function(){return [_c('a-button',{staticClass:"btn-success",on:{"click":function($event){return _vm.$store.dispatch('saveChatConfig')}}},[_vm._v(" "+_vm._s(_vm.$t('chat_config_save_button'))+" ")])]},proxy:true}])}),_c('a-card',{staticClass:"mt-5",attrs:{"title":_vm.$t('field_manual_moderation_extra_settings_title')}},[_c('switch-input',{attrs:{"setup":{
        'func': _vm.configInputSetup,
        'args': {
          'model': _vm.stateChatConfigPath,
          'key': 'remove_bots_commands',
          'hasAnchor': true,
        }
      }}}),_c('switch-input',{attrs:{"setup":{
        'func': _vm.configInputSetup,
        'args': {
          'model': _vm.stateChatConfigPath,
          'key': 'enable_command_aliases',
          'hasAnchor': true,
          'disabled': _vm.$store.state.chatState.chat.language_code !== 'ru'
        }
      }}})],1),_c('a-card',{staticClass:"mt-5",attrs:{"title":_vm.$t('field_mute_command_title_title')}},[_c('a-alert',{staticClass:"mx-2"},[_c('span',{attrs:{"slot":"description"},domProps:{"innerHTML":_vm._s(_vm.$t('mute_command_title_help_message'))},slot:"description"})]),_c('multi-message-editor-with-media-input',{attrs:{"setup":{
        'func': _vm.configInputSetup,
        'args': {
          'model': _vm.stateChatConfigPath,
          'key': 'mute_command_text',
          'placeholders': _vm.MODERATION_COMMANDS_PLACEHOLDERS,
          'targetToUpload': function () { return _vm.groupUploadTarget; },
          'editorMode': _vm.MediaEditorMode.TelegramShort,
          'hasMedia': _vm.$store.getters.getHaveLicense && _vm.$store.getters.getHaveBrand,
          'availableButtonsTypes': _vm.defaultMessageEditorButtons,
          'base-api-url': 'api.chatkeeper.app/cabinet',
          'hasAnchor': true,
        },
      }}}),_c('switch-input',{attrs:{"setup":{
        'func': _vm.configInputSetup,
        'args': {
          'model': _vm.stateChatConfigPath,
          'key': 'mute_command_remove',
          'hasAnchor': true,
        }
      }}}),_c('transition',{attrs:{"name":"fade"}},[(!_vm.$store.state.chatState.chat.config.mute_command_remove)?_c('nested-content',[_c('switch-input',{attrs:{"setup":{
            'func': _vm.configInputSetup,
            'args': {
              'model': _vm.stateChatConfigPath,
              'key': 'mute_command_reply',
              'hasAnchor': true,
            }
          }}})],1):_vm._e()],1)],1),_c('a-card',{staticClass:"mt-5",attrs:{"title":_vm.$t('field_ban_command_title_title')}},[_c('multi-message-editor-with-media-input',{attrs:{"setup":{
        'func': _vm.configInputSetup,
        'args': {
          'model': _vm.stateChatConfigPath,
          'key': 'ban_command_text',
          'placeholders': _vm.MODERATION_COMMANDS_PLACEHOLDERS,
          'targetToUpload': function () { return _vm.groupUploadTarget; },
          'editorMode': _vm.MediaEditorMode.TelegramShort,
          'hasMedia': _vm.$store.getters.getHaveLicense && _vm.$store.getters.getHaveBrand,
          'availableButtonsTypes': _vm.defaultMessageEditorButtons,
          'base-api-url': 'api.chatkeeper.app/cabinet',
          'hasAnchor': true,
        },
      }}}),_c('switch-input',{attrs:{"setup":{
        'func': _vm.configInputSetup,
        'args': {
          'model': _vm.stateChatConfigPath,
          'key': 'ban_command_remove',
          'hasAnchor': true,
        }
      }}})],1),_c('a-card',{staticClass:"mt-5",attrs:{"title":_vm.$t('field_kick_command_title_title')}},[_c('multi-message-editor-with-media-input',{attrs:{"setup":{
        'func': _vm.configInputSetup,
        'args': {
          'model': _vm.stateChatConfigPath,
          'key': 'kick_command_text',
          'placeholders': _vm.MODERATION_COMMANDS_PLACEHOLDERS,
          'targetToUpload': function () { return _vm.groupUploadTarget; },
          'editorMode': _vm.MediaEditorMode.TelegramShort,
          'hasMedia': _vm.$store.getters.getHaveLicense && _vm.$store.getters.getHaveBrand,
          'availableButtonsTypes': _vm.defaultMessageEditorButtons,
          'base-api-url': 'api.chatkeeper.app/cabinet',
          'hasAnchor': true,
        },
      }}}),_c('switch-input',{attrs:{"setup":{
        'func': _vm.configInputSetup,
        'args': {
          'model': _vm.stateChatConfigPath,
          'key': 'kick_command_remove',
          'hasAnchor': true,
        }
      }}}),_c('transition',{attrs:{"name":"fade"}},[(!_vm.$store.state.chatState.chat.config.kick_command_remove)?_c('nested-content',[_c('switch-input',{attrs:{"setup":{
            'func': _vm.configInputSetup,
            'args': {
              'model': _vm.stateChatConfigPath,
              'key': 'kick_command_reply',
              'hasAnchor': true,
            }
          }}})],1):_vm._e()],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }